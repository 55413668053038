@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

body {
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: #000000;
    }

input:placeholder-shown {
    font-style: italic;
    }




.fieldConatiner {
    display: flex;
    letter-spacing: .2rem;
    width: 300px;
    background: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
}

.loginTitle {
    margin: 26px;
}

.lineStyle {
    color:black;
    background-color: #4b4c4c;
    height: 1px;
    width: 100%;
}

.slidable {
    transition: transform 0.5s ease;
    transform: translateY(0); /* Initial position */
  }
  
.doslide {
    transform: translateY(100vh); /* Slide down out of the screen */
  }


.login-container form, .publicMessageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

.login-container {

    text-align: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin: auto;
    text-align: center;    }

.Registration {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 30vw;
    padding: 2vw 1vw;
    background-color: rgb(250, 194, 194);
    border-radius: 0 0 10px 10px;
    }

.userData {
    margin: auto;
    text-align: center;

}

.iconPlacement {
    background-position: 8px 7px !important;
    background-size: 20px 20px !important;
    width: 36px;
    height: 36px;
    position: absolute;
    background-color: white !important;
    border-radius: 30px;
    margin-left: 2px;
    margin-top: 2px;
}

.p0 {
    padding-left: 55px;
}

.loginLinks1 {
    display: flex;
    width: 300px;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 13px;
}

.titleBack {
    background-color: white;
    margin-bottom: 62px;
    margin-top: 63px;
    border-radius: 50px;
}

.login-container input {
    outline: none;
    vertical-align: top;
    width: 300px;
    border: none;
    height: 40px;

    border-radius: 20px;
    line-height: 40px;
    letter-spacing: 0.25px;
    font-family: "Google Sans",arial,sans-serif;
    box-sizing: border-box;
    margin-bottom: 30px;
    background-color: #000000d6;
    color: white;
    }

    .login-container inputField:hover{
        cursor: text;
    }

.submit_button {
    background-color: rgb(129, 129, 129);
    color :rgb(214, 214, 214)
}
.big {
    font-size: x-large;
    /* font-family: 'Abril Fatface', cursive; */
    font-family: 'Quicksand', sans-serif;
    color: #232121;
    letter-spacing: 5px;
}

.bigger {
    font-size: xx-large;
}

.error {
    color: red;
    margin-top: 2rem;
}

.success {
    color: green;
    margin-top: 2rem;
}

.flexcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.q1 {
    font-size: 20px;
     /* background-color: #F3F5F6; */
      padding: 0 10px;
      width:50px;
      margin-top: 5px;
}

[type=submit] {
    color: limegreen;
}

.m10 {
    margin-top:10px;
    margin-bottom: 10px;
}

.q2 {
    width: 100%;
     height: 20px;
      border-bottom: 1px solid black;
       text-align: center;
       margin-bottom: 50px;
}

.submit_button:hover{
    cursor: pointer;
    background-color: #3b3b3bd6;
    transition : ease 0.2s all;

}

.clickable2:hover{
    color: #ebebebd6;
    transition : ease 0.2s all;
    cursor: pointer;


}

.clickable2 {
        padding-bottom: 10px;
    }


.cContainer {
    display: flex;
    align-items: center;
}

.cTop {

    width: 20px;
    background-color: black;
    height: 2px;
    margin-left: 10px;
    border-radius: 10px;
    /* position: relative; */
}


.cIn {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 10px;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
}

.cOn {
    float:right;
}