.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.disabledRow {
  background-color: white;
  opacity: 50%;
  pointer-events: none;
}

.pickerCompo:hover {  
  filter: drop-shadow(.1rem .1rem 0.2rem rgb(88, 88, 88));
  /* filter: blur(10px); */
}

.pickerCompo {
  display: block;
  letter-spacing: .2rem;
  margin-bottom: 8px;
  margin-right: 15px;
  height: 45px;
  border-radius: 20px;
}

.pickerCompo label{
  padding-left: 10px;
}

label checkBoxDiv {

}
.pickerCompo input[type="checkbox"] {
  display: none; 
}

.pickerCompo input[type="checkbox"] + label {
  cursor: pointer;
  background-color: #e1dfdf;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  border-radius: 20px;
}

/* .pickerCompo input[type="checkbox"]:checked + label {
  background-color: green;
} */

.pickerCompo input[type="checkbox"] + label div {
  cursor: pointer;
  padding: 5px;
  background-color: rgb(78, 78, 78);
  border-radius: 5px;
  width: 5px;
  height:5px;
  margin-right: 10px;
}


.pickerCompo input[type="checkbox"]:checked + label .checkBoxDiv {
  background-color: rgb(255, 255, 255);
}